import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { LocationSwitch } from "../LocationSwitch";

import * as styles from "./Menu.module.scss";

const variants = {
  open: {
    x: 0,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    transition: {
      type: "spring",
      stiffness: 240,
      damping: 30,
      restDelta: 20,
    },
  },
  closed: {
    x: "-100vw",
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = ({ items, toggle, location }) => (
  <motion.ul variants={variants} className={styles.navigation}>
    <div className={styles.navInner}>
      {items &&
        items.map((item, index) => (
          <MenuItem item={item} key={index} toggle={toggle} />
        ))}
      {/* <div className={styles.locationSwitch}>
        <LocationSwitch location={location} toggle={toggle} />
      </div> */}
    </div>
    <button onClick={toggle}>
      <span className="visually-hidden">close menu</span>
    </button>
  </motion.ul>
);
